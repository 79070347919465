<template>
    <div class="page">
        <div class="goods">
            <div class="home">
                <span @click="cardetails">二手车详情</span><i class="el-icon-arrow-right" style="margin-top: 3px;"></i>
                <span style="color: #999999;">参数配置</span>
            </div>
        </div>
        <el-row>
            <el-col :span="24" class="title">
                <span>奥迪A4L 2020款 40 TFSI 时尚动感型</span>
            </el-col>
        </el-row>
        <div class="into">
            <div class="into-left">
                <div class="toc-affix">
                    <a-anchor @click="handleClick">
                        <a-anchor-link href="#A" title="基本信息" />
                        <a-anchor-link href="#B" title="车身参数" />
                        <a-anchor-link href="#C" title="发动机参数" />
                        <a-anchor-link href="#D" title="变速箱参数" />
                        <a-anchor-link href="#E" title="底盘参数" />
                        <a-anchor-link href="#F" title="车轮制动" />
                    </a-anchor>
                </div>
            </div>
            <div class="into-right">
                <el-descriptions id="A" title="基本信息" :column="3" border>
                    <el-descriptions-item v-for="(key, value) in informationlist" :key="key" :label="value">
                        {{key}}
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions id="B" title="车身参数" :column="3" border>
                    <el-descriptions-item v-for="(key, value) in bodyList" :key="key" :label="value">
                        {{key}}
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions id="C" title="发动机参数" :column="3" border>
                    <el-descriptions-item v-for="(key, value) in EngineList" :key="key" :label="value">
                        {{key}}
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions id="D" title="变速箱参数" :column="3" border>
                    <el-descriptions-item v-for="(key, value) in TransmissionList" :key="key" :label="value">
                        {{key}}
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions id="E" title="底盘参数" :column="3" border>
                    <el-descriptions-item v-for="(key, value) in chassisList" :key="key" :label="value">
                        {{key}}
                    </el-descriptions-item>
                </el-descriptions>
                <el-descriptions id="F" title="车轮制动" :column="3" border>
                    <el-descriptions-item v-for="(key, value) in wheelList" :key="key" :label="value">
                        {{key}}
                    </el-descriptions-item>
                </el-descriptions>
            </div>
        </div>
    </div>

</template>


<script>
export default {
    data() {
        return {
            //基本信息
            informationlist: {
                品牌: "奥迪",
                车辆类型: "—",
                厂商指导价万: "31.88万",
                排量: "2.0",
                变速箱: "自动",
                驱动方式: "前置驱动",
                油耗: "6.1",
                发动机: "DTA",
                长X宽X高: "4858X1847X1439(mm)",
                年款: "2020",
                上市日期: "2020",
                停产日期: "—",
                环境标准: "国VI",
                产地属性: "合资",
                整车质保: "三年或10万公里",
            },
            //车身信息
            bodyList: {
                长X宽X高: "4858X1847X1439(mm)",
                轴距: "2908(mm)",
                前轮距: "0(mm)",
                后轮距: "0(mm)",
                最小离地间隙: "118(mm)",
                车身结构: "承载式",
                整备质量: "1615（kg）",
                总质量: "0（kg）",
                载重量: "—",
                行李箱容积: "424（L）",
                车门数: "4（个）",
                座位数: "5",
                环保标准: "国VI",
                风阻系数: "—",
            },
            //发动机参数
            EngineList: {
                发动机型号: "DTA",
                排量: "2.0T",
                进气型式: "涡轮增压",
                气缸排列形式: "L",
                油箱容积: "56（L）",
                气缸数: "4（个）",
                每缸气门数: "4（个）",
                压缩比: "—",
                气门结构: "DOHC（凸轮轴）",
                缸径: "—",
                冲程: "—",
                最大马力: "190.0（Ps）",
                最大功率: "140.0（kW）",
                最大功率转速: "4200(rpm)",
                最大扭矩: "320.0（N·m）",
                最大扭矩转速: "1450(rpm)",
                发动机特有技术: "AVS",
                燃油标记: "95号",
                燃料形式: "汽油",
                供油方式: "混合喷射",
                缸盖材料: "铝合金",
                缸体材料: "铸铁",
                最高车速: "230（km/h）",
                官方0_100加速: "8.2(s)",
            },
            //变速箱参数
            TransmissionList: {
                变速箱类型: "湿式双离合（DCT",
                挡位个数: "7",
                变速箱简称: "7挡湿式双离合",
            },
            //底盘参数
            chassisList: {
                驱动方式: "自动",
                四驱形式: "-",
                前悬挂类型: "-",
                后悬挂类型: "五连杆独立悬架",
                中央差速器结构: "-",
                助力类型: "电动助力",
                车体结构: "承载式",
            },
            //车轮制动
            wheelList: {
                前制动器类型: "通风盘式",
                后制动器类型: "实心盘式",
                驻车制动类型: "电子驻车",
                前轮胎规格: "245/40 R18",
                后轮胎规格: "245/40 R18",
                备胎非全尺寸: "非全尺寸",
            },
        };
    },

    methods: {
        handleClick(e, link) {
            e.preventDefault();
            console.log(link);
        },
        cardetails() {
            this.$router.push("/cardetails");
        },
    },
};
</script>

<style lang="less" scoped>
.page {
    width: 80%;
    height: 1400px;
    margin: 0 auto;
    .into {
        display: flex;
        width: 80%;
        .into-left {
            width: 20%;
            .toc-affix {
                position: fixed;
                top: 170px;
                background: #fff;
                z-index: 1000;
                color: #000;
                text-decoration: none;
                // .a-anchor-link {
                //     color: #000;
                //     text-decoration: none;
                // }
            }
        }
        .into-right {
            width: 80%;
            height: 1200px;
        }
    }
}
.title {
    height: 40px;
    line-height: 40px;
    font-size: 26px;
    margin: 0;
}
.el-descriptions {
    margin: 15px 0;
}
.home {
    width: 20%;
    height: 40px;
    line-height: 40px;
    // margin: 0px 20px;
    cursor: pointer;
}
.ant-anchor-link-title {
    text-decoration: none;
    color: #000000 !important;
}
</style>
